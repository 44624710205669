import { post } from '../tool'
import { getBaseUrl } from './httpConfig';

export interface Data {
  state: number;  //订单支付状态：1 订单已经初始化生成 2 订单预支付完成 3 订单支付完成 4 订单超时 5 订单取消
  pay_money: number; //支付金额
  vip_level: number; //'商品类型 1-免费 2-商业 4-企业',
  square_num: number;  //方数
  duration: number;  //购买月份数
  give_duration: number; //赠送月份数
}
interface ResponseData {
  code: number;
  msg: string;
  data: Data
}
/**
 * 获取订单状态
 */
export async function requestGetOrderStatus(orderId: string): Promise<ResponseData> {
  try {
    const result = await post(getBaseUrl() + '/order/status', {
      order_id: orderId
    }, {
      headers: {
        Authorization: sessionStorage.getItem('webBackstageToken')
      },
    })

    const { code, msg } = result.data
    if (code !== 0) {
      throw new Error(msg || '查询订单状态失败，请稍后重试')
    }
    return result.data;
  } catch (error) {
    throw new Error(error.message)
  }
}
