import React, { useEffect, useState, useCallback } from "react"
import { LoadingOutlined, CheckCircleFilled } from "@ant-design/icons"
import { useIntl } from "gatsby-plugin-intl"
import styles from "./index.module.css"
import {
  Data,
  requestGetOrderStatus,
} from "../../api/payment/requestGetOrderStatus"
import moment from "moment"

interface Props {
  orderId: string
}
const vipLevelMap: { [key: number]: string } = {
  1: "免费版",
  2: "商业版",
  4: "企业版",
}

//订单状态查询轮询定时器
let timer: NodeJS.Timeout

function generateDateLabel(
  duration: number,
  zeroLabel: string = "连续包月"
): string {
  if (duration === 0) return zeroLabel
  if (duration >= 12) return moment.duration(duration, "month").years() + "年"
  return `${duration}个月`
}

/**
 * 订单支付状态查询模块
 */
function OrderStatus(props: Props) {
  const { orderId } = props
  const intl = useIntl()

  const [gotResult, setGotResult] = useState(false)
  const [orderDetail, setOrderDetail] = useState({} as Data)

  //查询订单状态
  const checkOrderStatus = useCallback(async (orderId: string) => {
    try {
      const { data } = await requestGetOrderStatus(orderId)
      if (data.state >= 3) {
        clearInterval(timer)
        if (data.state === 3) {
          //支付成功
          setOrderDetail(data)
          setGotResult(true)
        } else if (data.state === 4) {
          alert(intl.formatMessage({ id: "订单超时" }))
        } else if (data.state === 5) {
          alert(intl.formatMessage({ id: "订单取消" }))
        }
      }
    } catch (error) {
      clearInterval(timer)
    }
  }, [])

  useEffect(() => {
    console.log("orderId", orderId)

    if (orderId) {
      const nowSnapshot = Date.now()
      //轮询查询订单状态，每个一秒查询一次，查询 60s 后停止查询
      timer = setInterval(() => {
        if (Date.now() - nowSnapshot >= 60000) {
          clearInterval(timer)
          alert(intl.formatMessage({ id: "查询订单状态超时" }))
          return
        }
        checkOrderStatus(orderId)
      }, 1500)
    }
    return () => {
      clearInterval(timer)
    }
  }, [checkOrderStatus, orderId])

  //退出页面清除订单状态轮询查询定时器
  useEffect(() => {
    return () => {
      clearInterval(timer)
    }
  }, [])

  const vipLabel = vipLevelMap[orderDetail.vip_level]
  const payMoney =
    typeof orderDetail.pay_money === "number"
      ? orderDetail.pay_money.toFixed(2)
      : 0
  const durationLabel = generateDateLabel(orderDetail.duration)

  return (
    <div className={styles.orderStatusContainer}>
      {gotResult ? (
        <div>
          <p className={styles.paySuccess}>
            <CheckCircleFilled className={styles.resultIcon} />
            {intl.formatMessage({ id: "支付成功" })}
          </p>
          <div className={styles.infoWrapper}>
            <p className={styles.infoItem}>
              {intl.formatMessage({ id: "购买套餐：" })}
              {intl.formatMessage({ id: vipLabel })}
            </p>
            <p className={styles.infoItem}>
              {intl.formatMessage({ id: "实付金额：" })}
              {payMoney}
              {intl.formatMessage({ id: "元" })}
            </p>
            <p className={styles.infoItem}>
              {intl.formatMessage({ id: "购买时长：" })}
              {intl.formatMessage({ id: durationLabel })}
            </p>
            <p className={styles.infoItem}>
              {intl.formatMessage({ id: "会议人数上限：" })}
              {orderDetail.square_num}人
            </p>
          </div>
        </div>
      ) : (
        <div>
          <p className={styles.payChecking}>
            <LoadingOutlined className={styles.resultIcon} />
            {intl.formatMessage({ id: "支付结果查询中..." })}
          </p>
        </div>
      )}
    </div>
  )
}

export default React.memo(OrderStatus)
